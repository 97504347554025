import React, { PureComponent, Fragment } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'
import { chunk } from "lodash"
import './MainPage.scss'
import MediaQuery from 'react-responsive'
import { InView } from 'react-intersection-observer'
import ReactSnapScroll from './ReactSnapScroll'
import { onInitialClientRender } from '../../../gatsby-browser';


const INFO_BUTTON_HEIGHT = '40'
let COUNTER = 0
const ACTIVE_MAP = {
  0: COUNTER++,
  old: COUNTER++,
  roma: COUNTER++,
  trans: COUNTER++,
  cancer: COUNTER++,
  gender: COUNTER++,
  invalid: COUNTER++,
  lgbtk: COUNTER++,
  drugs: COUNTER++,
  migrants: COUNTER++,
  hiv: COUNTER++,
}
const newsMainSlugs = {
	roma: 'roma',
	old: 'elderly',
	invalid: 'disability',
	cancer: 'onko',
	migrants: 'migrants',
	gender: 'gender',
	hiv: 'hiv',
	lgbtk: 'lgbtq',
	trans: 'transgender',
	drugs: 'drugs'
}
class RenderImages extends PureComponent {
  render() {
    const getLessonLink = title => (title ? `${newsMainSlugs[title]}/lessons/` : '#')

    const handleInView = (inView, title) => {
      inView && handleMouseOver(title)
    }
    const { images, handleMouseOver, handleMouseOut } = this.props
    let imagesDevider = 3
    if (images.length % imagesDevider === 1) {
      imagesDevider = 3
    }
    const imagesRow = chunk(images, imagesDevider);
    return (
        <Fragment>
          <MediaQuery query="(min-device-width: 868px)">
            <div className="b-mainpage" role="main">
              <ReactSnapScroll 
                customTransition="b-mainpage" 
                customDuration={{enter: 200, exit: 800}}
                images={imagesRow}
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
              />
            </div>
          </MediaQuery>
          <MediaQuery query="(max-device-width: 867px)">
            {images.map((image, key) => ( <InView
              tag="div"
              className={cn(
                `b-mainpage__item b-mainpage__item--${image.title}`
              )}
              threshold={0.7}
              onChange={inView => handleInView(inView, image.title)}
              key={key}
            >
              <Link
                title={image.lessonLink.title}
                className={`b-mainpage__link b-mainpage__link--${image.title}`}
                to={getLessonLink(image.title)}
                onMouseOver={() => handleMouseOver(image.title)}
                onMouseOut={() => handleMouseOut()}
              > 
                <Img className={`b-mainpage__image`} fluid={image.image.fluid} alt={image.lessonLink.title}/>
              </Link>
            </InView>
          ))}
        </MediaQuery>
      </Fragment>
    )
  }
}

const RenderInfo = props => {
  const { images, active } = props
  return <div className={cn('b-button b-button--primary b-mainpage__info')} >
      <div className="b-mainpage__info-slider" style={{ transform: `translateY(${-ACTIVE_MAP[active] * INFO_BUTTON_HEIGHT}px)` }}>
        <div className="b-mainpage__info-item">
          Выбери категорию
        </div>
        {images.map((image, key) => (
          <div className="b-mainpage__info-item" key={key}>
            {image.lessonLink.title}
          </div>
        ))}
      </div>
    </div>
}
export default class MainPage extends PureComponent {
  constructor(props) {
    super(props)
    
  }
  state = {
    activeImage: 0,
    isAnyImageHovered: false,
  }
  componentDidMount() {
    document.querySelector("html").classList.add("main-page")
    onInitialClientRender()
  }
  componentWillUnmount() {
    document.querySelector("html").classList.remove("main-page")
  }
  
  handleMouseOver = (item) => {
      this.setState({
        activeImage: item,
      })
  }

  handleMouseOut = () => {
    this.setState({
      activeImage: 0,
    })
  }
  render() {
    const { isBgLoaded, images } = this.props
    
    return <Fragment> 
      <RenderInfo images={images} isBgLoaded={isBgLoaded} active={this.state.activeImage} isAnyImageHovered={this.state.isAnyImageHovered}/>
      <RenderImages images={images} isBgLoaded={isBgLoaded} handleMouseOut={this.handleMouseOut} handleMouseOver={this.handleMouseOver} />
    </Fragment>
  }
}
