import React from 'react'
import  MainImage from './MainImage'

const RenderDesktopImages = ({imagesRow, activePage, direction, handleMouseOver, handleMouseOut}) => {
return (
		imagesRow.map((images, index) => {
			return <div className="b-mainpage__items-row" key={"row" + index}> 
				{images.map((image, key) => {
					return <MainImage 
						item={image} 
						activePage={activePage}
						direction={direction} 
						index={index} 
						prevPage="PREVPAGE" 
						dkey={key} 
						key={image.title}
						handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
						/>
				})}
			</div>
		})
  )
}

export default RenderDesktopImages