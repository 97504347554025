import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import Seo from '../components/Seo'
import MainPage from '../components/MainPage'

class IndexPage extends PureComponent {
  render() {
    const { seo } = this.props.data
    const slug = this.props.location.pathname
    const images = seo.images
    return (
      <Layout currentSlug={slug}>
        <Seo {...seo} />
        <MainPage
          images={images}
        />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query indexQuery {
    seo: contentfulMainPage {
      title
      description
      image {
        file {
          url
        }
      }
      images {
        title
        lessonLink {
          slug
          title
        }
        image {
          id
          file {
            url
            fileName
            contentType
          }
          fluid(maxWidth: 641) {
            aspectRatio
            src
            base64
            sizes
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
    lessons: allContentfulLessons {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    librarys: allContentfulLibrary {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    mediaLibrarys: allContentfulMediaLibrary {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    nkos: allContentfulNko {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    tests: allContentfulTests {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    videoLibrarys: allContentfulVideoLibrary {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`
