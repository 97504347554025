import React  from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'

import { useSpring, animated } from 'react-spring'
const newsMainSlugs = {
	roma: 'roma',
	old: 'elderly',
	invalid: 'disability',
	cancer: 'onko',
	migrants: 'migrants',
	gender: 'gender',
	hiv: 'hiv',
	lgbtk: 'lgbtq',
	trans: 'transgender',
	drugs: 'drugs'
}
const MainImage = ({item, index, activePage, dkey, handleMouseOver, handleMouseOut}) => {

	const props = useSpring({
		to: {
			top: `${-activePage * 100 }vh`, 
			opacity: index === activePage ? 1 : -0.5,
			transform: `scale(${index === activePage ? 1 : 0.4}, ${index === activePage ? 1 : 0.6})`
		},
		config: {mass: 1, tension: 110, friction: 20, precision: 0.01},
		reset: false,
		delay: dkey * 100
	})
	
	const getLessonLink = title => (title ? `${newsMainSlugs[title]}/lessons/` : '#')
	const handleLoaded = (wasCached) => {
		const transRoot = typeof document !== `undefined` ? document.querySelector('.b-mainpage__link--old') : null
		const cancerRoot = typeof document !== `undefined` ? document.querySelector('.b-mainpage__link--roma') : null
		const genderRoot = typeof document !== `undefined` ? document.querySelector('.b-mainpage__link--trans') : null
		const linksArray = [transRoot, cancerRoot, genderRoot]
		!wasCached && linksArray && linksArray.map(item => item.classList.add('b-mainpage__link--show-animation'))
		wasCached && linksArray && linksArray.map(item => item.classList.add('b-mainpage__link--from-cache'))
	}
	return (
			<animated.div
				style={props}
				className={cn(
					`b-mainpage__item b-mainpage__item--${item.title}`
				)} 
				>
				<Link
						title={item.lessonLink.title}
						className={`b-mainpage__link b-mainpage__link--${item.title}`}
						to={getLessonLink(item.title)}
						// style={{height: '50vh'}}
						onMouseOver={() => handleMouseOver(item.title)}
            onMouseOut={() => handleMouseOut()}
					>
						<Img 
							className={`b-mainpage__image`} 
							loading="eager" 
							fadeIn={false}
							// objectFit="contain"
							// imgStyle={{objectFit: 'contain', height: '50vh'}}
							onStartLoad={(e) => {handleLoaded(e.wasCached)}}
							fluid={item.image.fluid} 
							alt={item.lessonLink.title}
						/>
					</Link>
			</animated.div>
	)
}

export default MainImage
